/** import { each } from 'lodash'**/
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  namespaced: true,
  state: {
    newList: [],
    earlierList: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    }
  },

  actions: {
    async getCertifications({ commit }, params) {}
  },

  mutations: {}
}
