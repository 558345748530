import User from '@/models/User'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { each, find } from 'lodash'
import env from '@/services/env'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    userDetails: null,
    userFeelings: null
  },

  mutations: {
    setUserList(state, users) {
      each(users, user => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new User(user))
        }
      })
    },

    clearUserList(state) {
      state.list = []
    },

    setUserListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserDetails(state, user) {
      if (user instanceof User) {
        state.userDetails = user
      } else {
        state.userDetails = new User(user)
      }
    },

    setUserFeelings(state, feelings) {
      state.userFeelings = feelings
    },

    setSelectedUserAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.userDetails.avatar = avatar
      } else {
        state.userDetails.avatar = new Avatar(avatar)
      }
    },

    clearUserDetails(state) {
      state.userDetails = null
    },

    userAvatarRemoved(state) {
      state.userDetails.avatar = null
    }
  },

  actions: {
    async getUsers({ commit }, params) {
      const query = User.page(params.page || 1)
        .custom('/admin/users')
        .include('avatar')
        .orderBy(params.sort)
        .where('with_blocked', 1)

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      const users = res.data.map(user => {
        let friendlyType = user.account_type
        let feelingIndicator = null

        if (friendlyType === 'helper') {
          friendlyType = 'Warrior'
        } else if (friendlyType === 'family') {
          friendlyType = 'Townsfolk'
        }

        if (friendlyType === 'Warrior') {
          feelingIndicator = [
            null,
            env.assetUrl + '/icons/feeling1.png',
            env.assetUrl + '/icons/feeling2.png',
            env.assetUrl + '/icons/feeling3.png',
            env.assetUrl + '/icons/feeling4.png',
            env.assetUrl + '/icons/feeling5.png'
          ][user.feeling_score]
        }

        return {
          ...user,
          account_type: friendlyType,
          feelingIndicator
        }
      })

      commit('setUserList', users)
      commit('setUserListMeta', res.meta)
    },

    async getUserDetails({ commit }, id) {
      const { data } = await Api.get(`admin/users/${id}`)
      commit('setUserDetails', data.data)

      if (data.data.account_type === 'helper') {
        const { data: feelingsResponse } = await Api.get(
          `admin/users/${id}/feelings`,
          {
            params: {
              limit: 7
            }
          }
        )

        const feelingData = feelingsResponse.data.map(feeling => {
          const feelingIndicator = [
            null,
            env.assetUrl + '/icons/feeling1.png',
            env.assetUrl + '/icons/feeling2.png',
            env.assetUrl + '/icons/feeling3.png',
            env.assetUrl + '/icons/feeling4.png',
            env.assetUrl + '/icons/feeling5.png'
          ][feeling.feeling_id]

          return {
            ...feeling,
            feelingIndicator
          }
        })
        commit('setUserFeelings', feelingData)
      }
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `admin/users/${formData.get('user_id')}/avatar`,
        formData
      )
      commit('setSelectedUserAvatar', data.data)
    },

    async resetUserPassword(_, { id, password }) {
      await Api.post(`admin/users/${id}/reset-password`, {
        password
      })
    },

    async updateUser({ commit }, formData) {
      const { data } = await Api.put(`admin/users/${formData.id}`, formData)
      commit('setUserDetails', data.data)
    },

    async deleteUser({ commit }, { id }) {
      await Api.delete(`admin/users/${id}`)
      commit('setUserDetails', null)
    },

    async blockUser({ commit }, { id, action }) {
      const { data } = await Api.post(`admin/users/${id}/${action}`)
      commit('setUserDetails', data.data)
    },

    async exportToExcel(_, type = null) {
      const file = await Api.get('admin/users/export', {
        params: {
          type: type
        },
        responseType: 'blob'
      })
      return file
    }
  }
}
